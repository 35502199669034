import React, { useMemo, useCallback } from 'react'
import {
  Switch, Route, Redirect
} from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import routes from '../../router/routes'
import Login from '../../views/login'
import CallbackPage from '../../views/callback'
import PrivateRoute from '../privateRoute'

function MainView() {
  const renderRoutes = useCallback(routesList => routesList.map((route) => {
    const {
      name = route.routes,
    } = route
    return (
      <PrivateRoute
        key={uuid()}
        pageName={name}
        {...route}
      />
    )
  }), [])

  const route = useMemo(() => renderRoutes(routes), [renderRoutes])

  return (
    <>
      <Switch>
        <Route
          path="/login"
          render={() => <Login />}
        />
        <Route
          path="/callback"
          render={() => <CallbackPage />}
        />
        {route}
        {/* 根目錄導到首頁 */}
        <Redirect from="*" to="/index" exact />

        {/* 其他導到查無此頁面 */}
        <Redirect from="*" to="/index" />
      </Switch>

    </>
  )
}

export default MainView
